import React, { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

import { checkLoginStatus } from "./redux/actions/userActions";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

const Header = lazy(() => import("./components/Header/Header"));
const SignIn = lazy(() => import("./pages/SignIn/SignIn"));
const AssignmentDashboard = lazy(() => import("./pages/Dashboard/AssignmentDashboard"));
const NewAssignment = lazy(() => import("./pages/Orders/NewAssignment"));
const AssignmentDetails = lazy(() => import("./pages/Details/AssignmentDetails"));
const AssignmentConversation = lazy(() => import("./pages/Conversations/AssignmentConversation"));



// Suspense fallback
const SuspenseFallback = () => {
  return (
    <div className="h-screen w-screen bg-transparent flex justify-center items-center">
      <ArrowPathIcon className="text-black w-8 h-8 animate-spin" />
    </div>
  );
};



function App() {
  const dispatch = useDispatch();



  // Check login status
  const loginStatus = useSelector((state) => state.user.logged__in);

  React.useEffect(() => {
    dispatch(checkLoginStatus());
  }, []);



  // Authentication
  const Authentication = () => {
    const token = localStorage.getItem("_token");
    const role = localStorage.getItem("_role");

    return token && role === "user" ? <Outlet /> : <Navigate to="/login/" />;
  };



  // Request notifications permission
  React.useEffect(() => {
    const askPermission = async () => {
      if (navigator.platform.startsWith("Win")) await Notification.requestPermission();
      if (navigator.platform.startsWith("Mac")) await Notification.requestPermission();
    }

    setTimeout(() => {
      askPermission();
    }, 2000)
  }, [])



  return (
    <Suspense fallback={<SuspenseFallback />}>
      <BrowserRouter>
        {loginStatus && <Header />}
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/" element={<Authentication />}>
            <Route path="/" element={<AssignmentDashboard />} />
          </Route>
          <Route path="/new-assignment" element={<Authentication />}>
            <Route path="/new-assignment" element={<NewAssignment />} />
          </Route>
          <Route path="/assignment-details/:id" element={<Authentication />}>
            <Route path="/assignment-details/:id" element={<AssignmentDetails />} />
          </Route>

          <Route path="/conversations" element={<Authentication />}>
            <Route path="/conversations" element={<AssignmentConversation />} />
          </Route>
          <Route path="/conversations/:room" element={<Authentication />} >
            <Route path="/conversations/:room" element={<AssignmentConversation />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
