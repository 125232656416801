import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleIcon, EnvelopeIcon, ArrowPathIcon, ArrowRightIcon, KeyIcon, LockClosedIcon, EyeSlashIcon, EyeIcon } from "@heroicons/react/20/solid";

import Logo from "../../assets/images/logo.png";
import ForgotPasswordImage from "../../assets/images/forgot-password-image.png";

import { requestToken, resetPassword } from "../../redux/actions/userActions";
import actionTypes from "../../redux/actions/actionTypes";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statusMessage = useSelector((state) => (state.user.status__message));

    // Request token
    const [next, setNext] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const [resetLoading, setResetLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const formSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        dispatch(requestToken(email));
    }

    const resetPasswordSubmit = (event) => {
        event.preventDefault();
        setResetLoading(true);

        dispatch(resetPassword({
            email,
            reset_password_token: token,
            new_password: password,
            confirm_password: confirmPassword,
            website_code: "SMAH"
        }))
    }

    React.useEffect(() => {
        if (statusMessage === actionTypes.USER.REQUEST_TOKEN_SUCCESS) {
            setLoading(false);
            setNext(true);
        } else if (statusMessage === actionTypes.USER.REQUEST_TOKEN_FAILED) setLoading(false);
        else if (statusMessage === actionTypes.USER.RESET_PASSWORD_SUCCESS) {
            setResetLoading(false);
            setPassword("");
            setToken("");
            setConfirmPassword("");
            setShowPassword(false);
            setNext(false);
            navigate("/login/");
        } else if (statusMessage === actionTypes.USER.RESET_PASSWORD_FAILED) setResetLoading(false);
    }, [statusMessage]);

    return (
        <div>
            <div className="py-3 px-3 md:px-8 flex justify-between items-center sticky top-0 bg-white">
                <img src={Logo} alt="Logo" className="w-full max-w-[160px]" />
            </div>
            <div className="flex p-3 md:p-10">
                <div className="w-full md:w-3/5">
                    <h2 className="text-2xl font-bold text-black">Forgot Your Account Password?</h2>
                    <p className="text-gray-500 mb-6 max-w-[400px]">Don't worry, you can reset your account password by requesting a verification token.</p>
                    <div className="max-w-[400px]">
                        <form onSubmit={formSubmit} className="flex mb-4">
                            <div className="flex items-center flex-col gap-4">
                                <div className="w-8 h-8 p-2 rounded-full bg-primary text-white flex items-center justify-center">1</div>
                                <div className="w-1 h-full rounded-full bg-primary"></div>
                            </div>
                            <div className="w-full ps-2">
                                {!next && <p className="text-primary mb-4">Request reset password token (verification token)</p>}
                                <div className="flex items-center gap-4 p-4 mb-1 bg-gray-200 w-full rounded-xl text-gray-500">
                                    <EnvelopeIcon width={16} />
                                    <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Enter your email address" className="outline-0 bg-transparent w-full" required autoFocus disabled={next} />
                                </div>
                                <p className="text-gray-400 mb-2">
                                    {
                                        !next ? "You will receive verification to this email."
                                            : `Verification token sent to ${email}. Check your inbox or spam for the email.`
                                    }
                                </p>
                                {!next && <button type="submit" className="p-2 inline-flex items-center gap-2 rounded-xl bg-primary text-white" disabled={loading}>
                                    Send Verification Token {
                                        loading ? <span className="animate-spin">
                                            <ArrowPathIcon width={16} />
                                        </span> : <ArrowRightIcon width={16} />
                                    }
                                </button>}
                            </div>
                        </form>


                        <form onSubmit={resetPasswordSubmit} className="flex">
                            <div className={`w-8 h-8 p-2 rounded-full flex items-center justify-center ${!next ? "bg-gray-200 text-gray-500" : "bg-primary text-white"}`}>2</div>
                            <div className="w-full ps-2">
                                <p className={`text-gray-500 mb-4`}>Verify token code and reset your password</p>
                                {
                                    next && <>
                                        <div className="flex items-center gap-4 p-4 mb-2 bg-gray-200 rounded-xl text-gray-500">
                                            <KeyIcon width={16} />
                                            <input type="text" value={token} onChange={(event) => setToken(event.target.value)} placeholder="Enter Verification Code" className="outline-0 bg-transparent" maxLength={6} required />
                                        </div>
                                        <div className="flex items-center gap-4 p-4 mb-2 bg-gray-200 rounded-xl text-gray-500">
                                            <LockClosedIcon width={16} />
                                            <input type="password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Create New Password" className="outline-0 bg-transparent w-full" required maxLength={16} />
                                            <button type="button" onClick={() => setShowPassword(prev => !prev)} className="p-1 text-black">
                                                {showPassword ? <EyeSlashIcon width={16} /> : <EyeIcon width={16} />}
                                            </button>
                                        </div>
                                        {showPassword && <p className="text-gray-500 mb-1 font-bold">Password: {password}</p>}
                                        <p className="text-gray-500 mb-3 text-[12px]">Your new password must includes @, 0-9, a-z, A-Z.</p>
                                        <div className="flex items-center gap-4 p-4 mb-2 bg-gray-200 rounded-xl text-gray-500">
                                            <LockClosedIcon width={16} />
                                            <input type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} placeholder="Confirm New Password" className="outline-0 bg-transparent" required />
                                        </div>
                                        <button type="submit" className="inline-flex items-center gap-2 bg-primary p-2 text-white rounded-xl">
                                            Verify & Reset Password  {
                                                resetLoading ? <span className="animate-spin">
                                                    <ArrowPathIcon width={16} />
                                                </span> : <ArrowRightIcon width={16} />
                                            }
                                        </button>
                                    </>
                                }
                            </div>
                        </form>



                    </div>
                </div>
                <div className="hidden md:block md:w-2/5">
                    <img src={ForgotPasswordImage} alt="Forgot Password" />
                </div>
            </div>
        </div>
    )
}