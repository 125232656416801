import actionTypes from "../actions/actionTypes";

const initialState = {
    orders: [],
    page: 1,
    pages: 1,

    count: 0,
   all__orders:[],

    select__row: 25,
    assignment__id: "",
    status: "New Order",
    substatus:"",

    created__id: null,

    assignment: null,

    solution: null,
    drafts: [],

    searched__order: [],

    status__message: null
}

const assignmentOrderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ORDER.GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                all__orders: payload,

                status__message: actionTypes.ORDER.GET_ALL_ORDERS_SUCCESS
            }

        case actionTypes.ORDER.GET_ALL_ORDERS_FAILED:
            return {
                ...state,
                all__orders: [],

                status__message: actionTypes.ORDER.GET_ALL_ORDERS_FAILED
            }
        case actionTypes.ORDER.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.orders,
                page: payload.page,
                pages: payload.pages,
                count: payload.count,

                select__row: payload.selectrow,
                assignment__id: payload.assignmentid,
                status: payload?.status,
                substatus:payload?.substatus,

                status__message: actionTypes.ORDER.GET_ORDERS_SUCCESS
            }

        case actionTypes.ORDER.GET_ORDERS_FAILED:
            return {
                ...state,
                orders: [],
                page: 1,
                pages: 1,

                select__row: 25,
                assignment__id: payload.assignmentid,
                status: payload?.status,
                substatus:payload?.substatus,

                status__message: actionTypes.ORDER.GET_ORDERS_FAILED
            }

        case actionTypes.ORDER.ADD_ORDER_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.ADD_ORDER_SUCCESS, created__id: payload }

        case actionTypes.ORDER.ADD_ORDER_FAILED:
            return { ...state, status__message: actionTypes.ORDER.ADD_ORDER_FAILED, created__id: null }

        case actionTypes.ORDER.GET_ASSIGNMENT_DETAILS_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.GET_ASSIGNMENT_DETAILS_SUCCESS, assignment: payload }

        case actionTypes.ORDER.GET_ASSIGNMENT_DETAILS_FAILED:
            return { ...state, status__message: actionTypes.ORDER.GET_ASSIGNMENT_DETAILS_FAILED, assignment: null }

        case actionTypes.ORDER.REMOVE_ASSIGNMENT_DETAILS:
            return { ...state, assignment: null }

        case actionTypes.ORDER.SEARCH_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                searched__order: payload,
                status__message: actionTypes.ORDER.SEARCH_ORDER_BY_ID_SUCCESS
            }

        case actionTypes.ORDER.SEARCH_ORDER_BY_ID_FAILED:
            return {
                ...state,
                searched__order: [],
                status__message: actionTypes.ORDER.SEARCH_ORDER_BY_ID_FAILED
            }

        case actionTypes.ORDER.REMOVE_SEARCH_ORDER_BY_ID:
            return { ...state, searched__order: [] }

        case actionTypes.ORDER.GET_SOLUTIONS_SUCCESS:
            return {
                ...state,
                solution: payload.solution,
                drafts: payload.drafts,
                status__message: actionTypes.ORDER.GET_SOLUTIONS_SUCCESS
            }

        case actionTypes.ORDER.GET_SOLUTIONS_FAILED:
            return {
                ...state,
                solution: null,
                drafts: [],
                status__message: actionTypes.ORDER.GET_SOLUTIONS_FAILED
            }

        case actionTypes.ORDER.REMOVE_SOLUTIONS:
            return {
                ...state,
                solution: null,
                drafts: [],
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, created__id: null, status__message: null }

        default: return state;
    }
}

export default assignmentOrderReducer;