import axios from "../../api/axios";
import { toast } from "react-toastify";
import actionTypes from "./actionTypes";

export const checkLoginStatus = () => (dispatch) => {
  const websiteCode = localStorage.getItem("_websitecode");
  const token = localStorage.getItem("_token");
  const role = localStorage.getItem("_role");

  const condition =
    token && role === "user" && websiteCode === "SMAH" ? true : false;

  dispatch({
    type: actionTypes.USER.CHECK_LOGIN_STATUS,
    payload: condition,
  });
};

export const userLogin = (data) => async (dispatch) => {
  await axios
    .post("/api/user/login", data)
    .then((response) => {
      if (response.status === 200) {
        console.log(response?.data);

        localStorage.setItem("_token", response?.data?.token);
        localStorage.setItem("_id", response?.data?.user?._id);
        localStorage.setItem("_email", response?.data?.user?.email);
        localStorage.setItem(
          "_websitecode",
          response?.data?.user?.website_code
        );
        localStorage.setItem("_role", response?.data?.user?.role);

        dispatch({ type: actionTypes.USER.SIGN_IN_SUCCESS });
        toast.success("Login Credentials Accepted!");

        setTimeout(() => {
          dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
        }, 1000);
      }
    })
    .catch((error) => {
      dispatch({ type: actionTypes.USER.SIGN_IN_FAILED });
      toast.error(error?.response?.data?.message);

      setTimeout(() => {
        dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
      }, 1000);
    });
};

export const userSignOut = () => (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.USER.SIGN_OUT });

  setTimeout(() => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }), 1000);
};

export const requestToken = (email) => async (dispatch) => {
  await axios
    .get(`/api/user/reset/password?email=${email}&website_code=SMAH`)
    .then((response) => {
      if (response?.status === 200) {
        localStorage.setItem("_token", response?.data?.token);
        toast.success(
          `Email verified! Reset Token has been sent to ${email}! Check your GMail inbox or spam for this email.`
        );
        dispatch({ type: actionTypes.USER.REQUEST_TOKEN_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.USER.REQUEST_TOKEN_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const resetPassword = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  await axios
    .patch("/api/user/reset/password", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        localStorage.clear();
        toast.success("Your account password reset successfully!");
        dispatch({ type: actionTypes.USER.RESET_PASSWORD_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.USER.RESET_PASSWORD_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const userDetails = () => async (dispatch) => {
  const id = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  await axios
    .get(`/api/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (
        response?.data?.user?.role === "user" &&
        response?.data?.user?.website_code === "SMAH"
      ) {
        dispatch({
          type: actionTypes.USER.USER_DETAILS_SUCCESS,
          payload: response?.data?.user,
        });
      } else dispatch(userSignOut());
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.USER.USER_DETAILS_FAILED });

      dispatch(userSignOut());
    });
};

export const updatePassword = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  await axios
    .patch("/api/user/update/password", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        toast.success("Password updated successfully!");
        dispatch({ type: actionTypes.USER.UPDATE_PASSWORD_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.USER.UPDATE_PASSWORD_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const updateProfile = (file) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  const data = new FormData();
  data.append("profile_pic", file);

  await axios
    .patch("/api/user/update/profilePic", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        dispatch({ type: actionTypes.USER.UPDATE_PROFILE_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.USER.UPDATE_PROFILE_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};
