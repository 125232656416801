import actionTypes from "../actions/actionTypes";

const initialState = {
    logged__in: false,

    user__details: null,

    status__message: null
}


const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.USER.CHECK_LOGIN_STATUS:
            return { ...state, logged__in: payload }

        case actionTypes.USER.SIGN_IN_SUCCESS:
            return {
                ...state,
                logged__in: true,
                status__message: actionTypes.USER.SIGN_IN_SUCCESS
            }

        case actionTypes.USER.SIGN_IN_FAILED:
            return {
                ...state,
                logged__in: false,
                status__message: actionTypes.USER.SIGN_IN_FAILED
            }

        case actionTypes.USER.SIGN_OUT:
            return {
                ...state,
                logged__in: false,
                user__details: null,
                status__message: actionTypes.USER.SIGN_OUT
            }

        case actionTypes.USER.REQUEST_TOKEN_SUCCESS:
            return { ...state, status__message: actionTypes.USER.REQUEST_TOKEN_SUCCESS }

        case actionTypes.USER.REQUEST_TOKEN_FAILED:
            return { ...state, status__message: actionTypes.USER.REQUEST_TOKEN_FAILED }

        case actionTypes.USER.RESET_PASSWORD_SUCCESS:
            return { ...state, status__message: actionTypes.USER.RESET_PASSWORD_SUCCESS }

        case actionTypes.USER.RESET_PASSWORD_FAILED:
            return { ...state, status__message: actionTypes.USER.RESET_PASSWORD_FAILED }

        case actionTypes.USER.USER_DETAILS_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.USER.USER_DETAILS_SUCCESS,
                user__details: payload
            }

        case actionTypes.USER.USER_DETAILS_FAILED:
            return {
                ...state,
                status__message: actionTypes.USER.USER_DETAILS_FAILED,
                user__details: null
            }

        case actionTypes.USER.UPDATE_PASSWORD_SUCCESS:
            return { ...state, status__message: actionTypes.USER.UPDATE_PASSWORD_SUCCESS }

        case actionTypes.USER.UPDATE_PASSWORD_FAILED:
            return { ...state, status__message: actionTypes.USER.UPDATE_PASSWORD_FAILED }

        case actionTypes.USER.UPDATE_PROFILE_SUCCESS:
            return { ...state, status__message: actionTypes.USER.UPDATE_PROFILE_SUCCESS }

        case actionTypes.USER.UPDATE_PROFILE_FAILED:
            return { ...state, status__message: actionTypes.USER.UPDATE_PROFILE_FAILED }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default userReducer;