const actionTypes = {
    USER: {
        CHECK_LOGIN_STATUS: "CHECK_LOGIN_STATUS",

        SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
        SIGN_IN_FAILED: "SIGN_IN_FAILED",

        SIGN_OUT: "SIGN_OUT",

        REQUEST_TOKEN_SUCCESS: "REQUEST_TOKEN_SUCCESS",
        REQUEST_TOKEN_FAILED: "REQUEST_TOKEN_FAILED",
        RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
        RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

        USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
        USER_DETAILS_FAILED: "USER_DETAILS_FAILED",

        UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
        UPDATE_PASSWORD_FAILED: "UPDATE_PASSWORD_FAILED",

        UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
        UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
    },

    STAGE: {
        GET_STAGES_SUCCESS: "GET_STAGES_SUCCESS",
        GET_STAGES_FAILED: "GET_STAGES_FAILED",
    },

    ORDER: {
        GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
        GET_ALL_ORDERS_FAILED: "GET_ALL_ORDERS_FAILED",

        GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
        GET_ORDERS_FAILED: "GET_ORDERS_FAILED",

        GET_ASSIGNMENT_DETAILS_SUCCESS: "GET_ASSIGNMENT_DETAILS_SUCCESS",
        GET_ASSIGNMENT_DETAILS_FAILED: "GET_ASSIGNMENT_DETAILS_FAILED",
        REMOVE_ASSIGNMENT_DETAILS: "REMOVE_ASSIGNMENT_DETAILS",

        UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
        UPDATE_ORDER_STATUS_FAILED: "UPDATE_ORDER_STATUS_FAILED",

        SEARCH_ORDER_BY_ID_SUCCESS: "SEARCH_ORDER_BY_ID_SUCCESS",
        SEARCH_ORDER_BY_ID_FAILED: "SEARCH_ORDER_BY_ID_FAILED",
        REMOVE_SEARCH_ORDER_BY_ID: "REMOVE_SEARCH_ORDER_BY_ID",

        GET_SOLUTIONS_SUCCESS: "GET_SOLUTIONS_SUCCESS",
        GET_SOLUTIONS_FAILED: "GET_SOLUTIONS_FAILED",
        REMOVE_SOLUTIONS: "REMOVE_SOLUTIONS",

        ADD_ORDER_SUCCESS: "ADD_ORDER_SUCCESS",
        ADD_ORDER_FAILED: "ADD_ORDER_FAILED"
    },

    MESSAGE: {
        MESSAGE_NOTIFICATIONS_SUCCESS: "MESSAGE_NOTIFICATIONS_SUCCESS",
        MESSAGE_NOTIFICATIONS_FAILED: "MESSAGE_NOTIFICATIONS_FAILED",

        MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
        MESSAGE_LIST_FAILED: "MESSAGE_LIST_FAILED",

        ROOM_MESSAGE_SUCCESS: "ROOM_MESSAGE_SUCCESS",
        ROOM_MESSAGE_FAILED: "ROOM_MESSAGE_FAILED",
        REMOVE_ROOM_MESSAGE: "REMOVE_ROOM_MESSAGE",
    },

    CLEAR_STATUS_MESSAGE: "CLEAR_STATUS_MESSAGE"
}

export default actionTypes;