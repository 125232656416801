import { combineReducers } from "redux";

import stageReducer from "./stageReducer";
import assignmentOrderReducer from "./assignmentOrderReducer";
import messageReducer from "./messageReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    user: userReducer,
    stage: stageReducer,
    assignmentorder: assignmentOrderReducer,
    message: messageReducer,
});

export default rootReducer;